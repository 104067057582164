/*
 *
 */
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';


const BoxItem = styled.div`
	font-size:1.5rem;
	line-height:1.5em;
	margin:auto;
	padding:1rem 0;
	box-sizing:border-box;

	@media (${props => props.theme.media.tablet}) {
		padding:1rem 1rem;
	}

`;

const Box = styled.div`
	
	display: flex;
	justify-content: center;
  	align-items: center;
  	flex-direction: column;
  	margin:auto;

	height:100%;
	background:#fff;
	border:1px solid #eaeaea;
	border-radius:.35rem;
	text-align:left;
	box-sizing: border-box;
	
	box-shadow:0 0 2rem 0 rgba(0,0,0,.05);
	
	h3 {
		color:#161a1e;
		font-weight:500;
		font-size:1.7rem;
		margin:0 0 1em;
	}
	p {
		border-bottom:1px solid transparent;
		transition:all .3s cubic-bezier(0.23,1,0.32,1);
		word-break: break-word;
		margin:0px;
		margin-bottom:2rem;	
		text-align: left;
		&:last-child {
			margin-bottom:0;
		}
	}

	@media (${props => props.theme.media.tablet}) {
		flex-direction: row;
		padding:0 3rem;
		width: 100%;
	}
`;


const LeftBox = styled.div`
	padding: 3rem;
	box-sizing:border-box;

	@media (${props => props.theme.media.tablet}) {
		width:50%;
	}
`;

const RightBox = styled.div`
	padding: 3rem;
	border-top: 1px solid #eaeaea;
	box-sizing:border-box;
	width: 100%;

	@media (${props => props.theme.media.tablet}) {
		border-left: 1px solid #eaeaea;
		border-top:none;
		width:50%;
	}

`;


const FeatureList = styled.ul`
	list-style: none;
	padding-inline-start: 0px;
`;

const Feature = styled.li`
	padding: 0.5em 0;
`;

const FeatureTitle = styled.li`
	padding: 0.5em 0;
	font-weight: 500;
`;

/*
 *
 */
 
export default function ProductFeaturing ({children}) {

	const intl = useIntl();

	return (
			<BoxItem>
				<Box>
					<LeftBox>
						<h2>{intl.formatMessage({ id: `page.restart.product_details.box.title` })}</h2>
						<h3>{intl.formatMessage({ id: `page.restart.product_details.box.subtitle` })}</h3>
						<p>{intl.formatMessage({ id: `page.restart.product_details.box.p1` })}</p>
						<p>{intl.formatMessage({ id: `page.restart.product_details.box.p2` })}</p>
					</LeftBox>
					<RightBox>
						<FeatureList>
							<FeatureTitle>{intl.formatMessage({ id: `page.restart.product_details.box.feat_title` })}</FeatureTitle>
							<Feature>{intl.formatMessage({ id: `page.restart.product_details.box.feat_1` })}</Feature>
							<Feature>{intl.formatMessage({ id: `page.restart.product_details.box.feat_2` })}</Feature>
							<Feature>{intl.formatMessage({ id: `page.restart.product_details.box.feat_3` })}</Feature>
							<Feature>{intl.formatMessage({ id: `page.restart.product_details.box.feat_4` })}</Feature>
							<Feature>{intl.formatMessage({ id: `page.restart.product_details.box.feat_5` })}</Feature>
							<Feature>{intl.formatMessage({ id: `page.restart.product_details.box.feat_6` })}</Feature>
							<Feature>{intl.formatMessage({ id: `page.restart.product_details.box.feat_7` })}</Feature>
						</FeatureList>
					</RightBox>
				</Box>
			</BoxItem>
	);
}