/*
 *
 */

import React from 'react';
import Section from 'components/section';
import Text from 'components/text';
import Button from 'components/button'
import HomeIntro from 'components/home-intro';
import Press from 'components/press';
import SectionBox from 'components/section-box';
import ProductFeaturing from 'components/product-featuring';
import { useIntl } from 'react-intl';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Helmet from 'react-helmet';


/*
 *
 */
export default function PackRelance () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.restart.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.restart.meta_desc' })} />
				<meta property="og:image" content={intl.formatMessage({ id: 'global.meta.og_image.restart' })} />
				<meta property="og:url" content={intl.formatMessage({ id: 'global.meta.og_url.restart' })} />

			</Helmet>
			<Section>
				<HomeIntro
					title={intl.formatMessage({ id: 'page.restart.hero.title' })}
					subtitle={intl.formatMessage({ id: 'page.restart.hero.subtitle' })}
					ctaText={intl.formatMessage({ id: 'page.restart.hero.ctaText' })}
					ctaLink={intl.formatMessage({ id: 'page.restart.hero.ctaLink' })}
					illustration='pack-restart'
				/>
			</Section>
			
			<Section>
				<Text>
					<h3>{intl.formatMessage({ id: 'page.restart.clients_logo.title' })}</h3>
					<div />
				</Text>
				<Press
					press={[
						{
							name: 'Santosha',
							logo: '/img/santosha.png',
						},
						{
							name: 'Bachir',
							logo: '/img/bachir.png',
						},
						{
							name: 'Il Brigante',
							logo: '/img/il-brigante.png',
						},
						{
							name: 'Trois Fois Plus',
							logo: '/img/troisfoisplus.png',
						}
					]}
				/>
			</Section>
			<Section type='color'>
				<ProductFeaturing></ProductFeaturing>
			</Section>
			{/*<Section>
				<Text>
					<Partners
						partners={[
							{
								name: intl.formatMessage({ id: 'page.restart.testimonial.1.business' }),
								description: intl.formatMessage({ id: 'page.restart.testimonial.1.text' }),
								logo: intl.formatMessage({ id: 'page.restart.testimonial.1.logo' }),
								url: intl.formatMessage({ id: 'page.restart.testimonial.1.website' })
							},
						]}
					/>
				</Text>
			</Section>*/}
			<Section>
				<Text>
					<h2>{intl.formatMessage({ id: 'page.restart.content_list.title' })}</h2>
					<p>{intl.formatMessage({ id: 'page.restart.content_list.subtitle' })}</p>
					<div></div>
				</Text>
				<SectionBox>	
					<h3>{intl.formatMessage({ id: 'page.restart.content_list.website.title' })}</h3>
					<p>{intl.formatMessage({ id: 'page.restart.content_list.website.description' })}</p>
				</SectionBox>
				<SectionBox>	
					<h3>{intl.formatMessage({ id: 'page.restart.content_list.google.title' })}</h3>
					<p>{intl.formatMessage({ id: 'page.restart.content_list.google.description' })}</p>
				</SectionBox>

				<SectionBox>
					<h3>{intl.formatMessage({ id: 'page.restart.content_list.reservation.title' })}</h3>
					<p>{intl.formatMessage({ id: 'page.restart.content_list.reservation.description' })}</p>
				</SectionBox>
				<SectionBox>
					<h3>{intl.formatMessage({ id: 'page.restart.content_list.food_order.title' })}</h3>
					<p>{intl.formatMessage({ id: 'page.restart.content_list.food_order.description' })}</p>
				</SectionBox>
				<SectionBox>
					<h3>{intl.formatMessage({ id: 'page.restart.content_list.qr_menu.title' })}</h3>
					<p>{intl.formatMessage({ id: 'page.restart.content_list.qr_menu.description' })}</p>
				</SectionBox>
				<SectionBox>
					<h3>{intl.formatMessage({ id: 'page.restart.content_list.support.title' })}</h3>
					<p>{intl.formatMessage({ id: 'page.restart.content_list.support.description' })}</p>
				</SectionBox>

				<Text>
				<br/>
				<h3>{intl.formatMessage({ id: 'page.restart.content_list.learn_more.title' })}</h3>
				<Button 
					target="_blank" 
					href={intl.formatMessage({ id: 'page.restart.hero.ctaLink' })}
					onClick={e => {		
			        // Lets track that custom click		
			        trackCustomEvent({		
			          // string - required - The object that was interacted with (e.g.video)		
			          category: "Button",		
			          // string - required - Type of interaction (e.g. 'play')		
			          action: "Buy Relance Pack",		
			          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')		
			          label: "Section - Pack Details"		
			        })		
			      	}}>
					{intl.formatMessage({ id: 'page.restart.content_list.learn_more.ctaText' })}
				</Button>
				</Text>
			</Section>
		</React.Fragment>
	);
}